import React, { Component, useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import style from './index.module.css'

const Theme1 = (props) => {
  // console.log('propsprops', props)
  const [shouldShowFlag, setshouldShowFlag] = useState(true)
  const [countryFlag, setcountryFlag] = useState([])
  const [openList, setopenList] = useState(false)
  const [showNoResultFound, setshowNoResultFound] = useState(false)
  const [country, setCountry] = useState(props.isCity?false: true)
  const statusFilterLayerRef = useRef(null)
  const statusFilterRef = useRef(null)
  const [isSignup, setIsSignup] = useState(props?.isSignup?true:false)



  function onStatusFilterClick(params) {
    if (!openList) {
      setopenList(true)
      document.addEventListener('click', handleClickOutsideOfonStatusFilterClick, true)
    } else {
      document.removeEventListener('click', handleClickOutsideOfonStatusFilterClick, true)
      setopenList(false)
      setshouldShowFlag(true)
    }
  }

  function handleClickOutsideOfonStatusFilterClick(event) {
    // console.log("statusFilterLayerRef", statusFilterLayerRef);
    if (statusFilterLayerRef.current && !statusFilterLayerRef.current.contains(event.target)) {
      setopenList(false)
      setshouldShowFlag(true)
      document.removeEventListener('click', handleClickOutsideOfonStatusFilterClick, true)
    }
  }
  return (
    <div onClick={onStatusFilterClick} ref={statusFilterLayerRef}>
      <span className={style.vt_country_select_main_container}>
        {/* {console.log('countrryflag1',shouldShowFlag)} */}
        <span
          role="presentation"
          className={isSignup ? style.signUp_country : style.vt_country_select_container}
          onClick={() => {
            setopenList(true)
            setshouldShowFlag(false)
            let countryFlag1 = props.countryFlag.filter((ele) => {
              return ele.value.includes(props.searchText)
            })
            countryFlag1.sort((a, b) => {
              return a.value - b.value
            })
            setcountryFlag(countryFlag1)
            setshowNoResultFound(false)
            if (countryFlag1.length > 0) {
              setshowNoResultFound(false)
            } else {
              setshowNoResultFound(true)
            }
          }}
        >
          {country && (props.searchText || props?.selectedCountry?.value) && shouldShowFlag && !openList && (props.isCountryFlagVisible?props.isCountryFlagVisible:false) && (
            <img
            // style={{ height: "30px", width: "50px" }}
            // className={style.vt_flag_img}

            className={`${props.selectedCountry?.flag == undefined ? '' : style.flag_style}`}
            src={
              props.selectedCountry !== undefined && props.selectedCountry !== null
                ? props.selectedCountry.flag
                : ''
            }
            alt="flag"
            onError={(e) => {
              e.target.onerror = null
              e.target.src = ''
            }}
          />
          )}
          <input
            // {...props}
            className={`   
            ${shouldShowFlag 
              ? style.vt_inspira_country_main
              : style.vt_inspira_country_main + style.vt_country_selected}
              ${isSignup
                  ? style.country_input
                  : ""}
              `
              }
            title={props.searchText}
            onChange={(e) => {
              let countryFlag1 = props.countryFlag.filter((ele) => {
                return ele.value.toLowerCase().includes(e.target.value.toLowerCase())
              })
              countryFlag1.sort((a, b) => {
                return a.value - b.value
              })
              setcountryFlag(countryFlag1)
              setopenList(true)
              setshouldShowFlag(false)
              props.onChangeInputCountryCode(e)
              if (countryFlag1.length > 0) {
                setshowNoResultFound(false)
              } else {
                setshowNoResultFound(true)
              }
            }}
            onKeyDown={(e) => {
              const key = e.key
              if (key === 'Backspace' || key === 'Delete') {
                setshouldShowFlag(false)
              }
              if (key === 'Tab') {
                setopenList(false)
                setshouldShowFlag(true)
              }
            }}
            value={props.searchText !== '' ? props.searchText : props?.selectedCountry?.value}
            placeholder={props.placeholder}
            autoComplete="new-password"
          />
          {props.isDropdownIconVisible && (
            <div className={style.flight_cabin_class_arrow}>
                <img src="theme4/arrowGray.svg" alt="dropdown" />
              {/* <svg width="15px" height="15px" viewBox="0 0 200 200"><polygon fill="currentColor" points="180.8,40.25 19.2,40.25 100.202,166.5 "></polygon></svg> */}
              {/* <img src="images/down_arrow.jpg" className={style.flight_cabin_class_arrow_img} alt=""/> */}
            </div>
          )}
        </span>

        {openList && !shouldShowFlag && (
          <div
            className={
              countryFlag.length <= 4
                ? style.vt_country_select_list_container
                : style.vt_country_select_list_container
            }
            ref={statusFilterLayerRef}
          >
            {showNoResultFound && (
              <div style={{ padding: '8px' }} className={style.no_result_found}>
                {'No Result Found !'}
              </div>
            )}

            {countryFlag.map((ele, index) => {
              // console.log('ele', ele?.flag)
              return (
                <div
                  className={style.country_name_code}
                  key={index}
                  onClick={() => {
                    setshouldShowFlag(true)
                    props.selectCountry(ele)
                  }}
                >
                  {' '}
                  {/* this.setState({ shouldShowFlag: true }) */}
                  {ele?.flag && (
                    <img
                      // style={{ height: "20px", width: "25px" }}
                      className={ele?.flag == undefined ? '' : style.flag_style}
                      src={ele?.flag !== 'NULL' && ele?.flag !== null && ele?.flag}
                      alt="flag"
                    />
                  )}
                  <span>{ele?.value}</span>
                  {ele.currency && <span>{ele.currency}</span>}
                  {ele.currencyCode && <span>{ele.currencyCode}</span>}
                </div>
              )
            })}
          </div>
        )}
      </span>
    </div>
  )
}
export default Theme1
