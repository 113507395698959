import CountryDropdown1 from './theme1'
import CountryDropdown2 from './theme2'
import CountryDropdown4 from './theme4'
import CountryDropdown5 from './theme5'

export default function CountryDropdown(props) {
  const { theme } = props

  switch (theme) {
    case 'theme1':
      return <CountryDropdown1 {...props} />
    case 'theme2':
      return <CountryDropdown2 {...props} />
    case 'theme4':
      return <CountryDropdown4 {...props} />
    case 'theme5':
      return <CountryDropdown5 {...props} />
    default:
      return <CountryDropdown1 {...props} />
  }
}
